import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild(IonModal) loginModal: IonModal;
  @Input('login') login: boolean = false;

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  clearCache: boolean = false;

  constructor(
    public authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    const user = this.authService.getUser();

    if (user?.user?.email) {
      this.form.patchValue({
        email: user.user.email,
      });
    }

    let url = this.router.url;

    if (!url.includes('auth')) {
      this.clearCache = true;
    }
  }

  logout() {
    this.authService.logout();
  }

  autoComplete(event: any, field: 'email' | 'password' = 'email') {
    const value = event?.target?.value ?? event;
    this.form.patchValue({
      [field]: value,
    });

    this.form.markAsDirty();
  }
}
