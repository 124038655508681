<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()" shape="round">
        <ion-icon slot="icon-only" icon="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Politica de utilizare cookie</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-text
    [innerHTML]="cookies"
  ></ion-text>
</ion-content>
