import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dash/checkout/checkout.module').then(
            (m) => m.CheckoutPageModule
          ),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/dash/checkout/checkout.module').then(
            (m) => m.CheckoutPageModule
          ),
      },
    ],
  },
  {
    path: 'demo',
    loadChildren: () =>
      import('./pages/dash/exam/play/play.module').then(
        (m) => m.PlayPageModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/dash/auth/auth.module').then((m) => m.AuthPageModule),
    canMatch: [AutoLoginGuard],
  },
  {
    path: 'dash',
    loadChildren: () =>
      import('./pages/dash/dash.module').then((m) => m.DashPageModule),
    canMatch: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/dash/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
    canMatch: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'exam',
    canMatch: [AuthGuard],
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dash/exam/exam.module').then((m) => m.ExamPageModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/dash/exam/exam.module').then((m) => m.ExamPageModule),
      },
      {
        path: 'play/:id',
        loadChildren: () =>
          import('./pages/dash/exam/play/play.module').then(
            (m) => m.PlayPageModule
          ),
      },
    ],
  },
  {
    path: 'performance',
    loadChildren: () =>
      import('./pages/dash/performance/performance.module').then(
        (m) => m.PerformancePageModule
      ),
    canMatch: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'forum',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dash/forum/forum.module').then(
            (m) => m.ForumPageModule
          ),
        canMatch: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/dash/forum/post/post.module').then(
            (m) => m.PostPageModule
          ),
        canMatch: [AuthGuard],
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'audio',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dash/podcast/podcast.module').then(
            (m) => m.PodcastPageModule
          ),
        canMatch: [AuthGuard],
        canActivate: [AuthGuard],
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/dash/podcast/episode/episode.module').then(
            (m) => m.EpisodePageModule
          ),
        canMatch: [AuthGuard],
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
