import { Component, Input } from '@angular/core';
import { Network } from '@capacitor/network';
import { AlertController, ModalController } from '@ionic/angular';
import { BaseService } from './services/base.service';
import { AuthenticationService } from './services/authentification.service';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  connected: boolean = true;

  constructor(
    private base: BaseService,
    private modal: ModalController,
    private alertCtrl: AlertController,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.base.keyboard();

    // Check network status
    Network.getStatus().then((status) => {
      this.connected = status.connected;
    });

    Network.addListener('networkStatusChange', async (status) => {
      this.connected = status.connected;

      // If is disconnected refresh every second
      if (!status.connected) {
        // Close all modals
        await this.modal.dismiss();
        await this.refresh(true);
      }
    });

    this.checkEmailConfirmation();
  }

  checkEmailConfirmation() {
    this.authService.isAuthenticated
      .pipe(
        filter((val) => val === true),
        take(1),
        map(() => {
          const user = this.authService.getUser();

          const today = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
          );
          const createdAt = new Date(user.user.created_at);
          const daysDifference = today.getDate() - createdAt.getDate();

          if (user.user.verified != 1 && daysDifference >= 1) {
            this.alertCtrl
              .create({
                header: 'Confirmare email',
                message:
                  'Te rugam sa accesezi link-ul de confirmare din email.',
                backdropDismiss: false,
                cssClass: 'alertEmailConfirmation',
                inputs: [
                  {
                    placeholder: 'Email',
                    name: 'email',
                    type: 'email',
                    value: user.user.email,
                    id: 'emailInput',
                  },
                ],
                buttons: [
                  {
                    text: 'Retrimite',
                    handler: () => {
                      const inputElement = <HTMLInputElement>document.getElementById('emailInput');
                      this.authService.resendEmailConfirmation(inputElement.value);
                    },
                  },
                  {
                    text: 'Verifica',
                    role: 'confirm',
                    handler: () => {
                      this.authService.refreshUserData().then(() => {
                        location.reload();
                      });
                    },
                  },
                ],
              })
              .then((alert) => {
                alert.present();
              });
          }
        }),
      )
      .subscribe();
  }

  async refresh(autorefresh = false) {
    await Network.getStatus().then((status) => {
      this.connected = status.connected;

      if (autorefresh && !status.connected) {
        // Refresh every second
        setTimeout(async () => {
          await this.refresh(true);
        }, 1000);
      }
    });
  }
}
