import { FbPixelService } from './fb-pixel.service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, lastValueFrom } from 'rxjs';

import { Storage } from '@ionic/storage-angular';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export interface User {
  user: any;
  token: string;
  refreshLogin: boolean;
}

const TOKEN_KEY = 'UMFPTken';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      x: environment.api_key,
    }),
  };

  userData: BehaviorSubject<any> = new BehaviorSubject(null);
  url = environment.api_url;
  refresh: boolean = true;

  constructor(
    private storage: Storage,
    private plt: Platform,
    private router: Router,
    private nav: NavController,
    private alertCtrl: AlertController,
    private http: HttpClient,
    private fbPixel: FbPixelService,
  ) {
    this.storage.create().then();
    this.loadToken().then();
  }

  async loadToken() {
    let platformObs = from(this.plt.ready());

    platformObs
      .pipe(
        switchMap(() => {
          return from(this.storage.get(TOKEN_KEY));
        }),
        map((data) => {
          data = JSON.parse(data);

          if (data?.token) {
            this.userData.next(data);
            this.isAuthenticated.next(true);
          } else {
            this.isAuthenticated.next(false);
          }
        }),
      )
      .subscribe();
  }

  register(form: any) {
    this.http
      .post(this.url + 'register', JSON.stringify(form), this.headers)
      .subscribe({
        next: async () => {
          // Track event
          this.fbPixel.trackEvent('CompleteRegistration', {
            email: form.email,
          });

          // Check if newsletter is checked
          if (form.newsletter) {
            this.fbPixel.trackEvent('Subscribe', {
              email: form.email,
            });
          }

          const alert = await this.alertCtrl.create({
            header: 'Înregistrare completă',
            message: 'Te rugam sa accesezi link-ul de confirmare din email.',
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  this.login(form);
                },
              },
            ],
          });
          await alert.present();
        },
        error: async (error) => {
          if (error.error?.errors?.email) {
            const alert = await this.alertCtrl.create({
              header: 'Înregistrare eșuată',
              message: 'Exista un cont cu acest email.',
              buttons: ['OK'],
            });
            await alert.present();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Înregistrare eșuata',
              message: 'Te rugăm să reîncerci sau să ne contactezi.',
              buttons: ['OK'],
            });
            await alert.present();
          }
        },
      });
  }

  async login(credentials: any) {
    this.http
      .post(this.url + 'auth', JSON.stringify(credentials), this.headers)
      .subscribe({
        next: async (data: any) => {
          if (data?.token) {
            // Track event
            this.fbPixel.trackEvent('Login', {
              email: credentials.email,
            });

            data = { refreshLogin: false, ...data };
            this.userData.next(data);
            this.isAuthenticated.next(true);

            await this.storage.set(TOKEN_KEY, JSON.stringify(data));

            // Redirect to check out if it has subscription_id in storage
            const hasCart = await this.storage.get('subscription');
            if (hasCart) {
              this.nav.navigateRoot('/checkout').then();
            } else {
              let url = this.router.url;
              if (url == '/auth') {
                this.nav.navigateRoot('/dash').then();
              } else if (this.refresh) {
                location.reload();
              }
            }

            this.refresh = true;

            return true;
          }

          return false;
        },
        error: async () => {
          const alert = await this.alertCtrl.create({
            header: 'Logare eșuată',
            message: credentials?.code
              ? 'Autentificarea a eșuat, te rugam sa reîncerci.'
              : 'Email-ul si parola nu corespund.',
            buttons: ['OK'],
          });

          await alert.present();

          return false;
        },
      });
  }

  async socialCheck(authCode: string) {
    return lastValueFrom(
      this.http.post(
        this.url + 'social',
        JSON.stringify({
          code: authCode,
        }),
        this.headers,
      ),
    );
  }

  forgot(form: any) {
    this.http
      .post(this.url + 'forgot', JSON.stringify(form), this.headers)
      .subscribe({
        next: async () => {
          const alert = await this.alertCtrl.create({
            header: 'Parola resetată cu succes',
            message: `Noua parolă a fost trimisă pe adresa de email: ${form.email}.`,
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  location.reload();
                },
              },
            ],
          });
          await alert.present();
        },
        error: async () => {
          const alert = await this.alertCtrl.create({
            header: 'Resetare eșuată',
            message:
              'Nu există un cont cu acest email. Creează-ti un cont sau contactează-ne.',
            buttons: ['OK'],
          });
          await alert.present();
        },
      });
  }

  async refreshUserData(): Promise<User> {
    let user = this.getUser();

    // add to header skipCheck
    this.headers.headers = this.headers.headers.set('skipCheck', 'true');

    return lastValueFrom(
      this.http.get(this.url + 'user/' + user.user.id, this.headers),
    ).then(async (data: any) => {
      user.user = data;
      this.userData.next(user);

      await this.storage.set(TOKEN_KEY, JSON.stringify(user));

      return user;
    });
  }

  getUser(): User {
    return this.userData.getValue();
  }

  isLoggedIn(): boolean | null {
    return this.isAuthenticated.getValue();
  }

  async refreshLogin(refresh: boolean = false) {
    this.refresh = refresh;
    let user = this.getUser();
    user.refreshLogin = true;
    this.userData.next(user);
    return from(await this.storage.set(TOKEN_KEY, JSON.stringify(user)));
  }

  logout(): void {
    this.storage.remove(TOKEN_KEY).then(() => {
      if (this.getUser() && !this.getUser().refreshLogin)
        this.http.post(this.url + 'logout', this.headers).subscribe();

      this.userData.next(null);
      this.isAuthenticated.next(false);
      this.nav.navigateRoot(['/auth']).then();
    });
  }

  resendEmailConfirmation(email: string) {
    let user = this.getUser();

    this.headers.headers = this.headers.headers.set(
      'Authorization',
      `Bearer ${this.getUser().token}`,
    );

    this.http
      .post(this.url + `user/${user.user.id}/resend/${email}`, this.headers)
      .subscribe({
        next: async () => {
          const alert = await this.alertCtrl.create({
            header: 'Email trimis cu succes',
            message: `Email-ul a fost trimis pe adresa de email: ${email}`,
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  this.refreshUserData().then(() => {
                    location.reload();
                  });
                },
              },
            ],
          });
          await alert.present();
        },
        error: async () => {
          const alert = await this.alertCtrl.create({
            header: 'Trimitere eșuată',
            message: 'Te rugăm să reîncerci sau să ne contactezi.',
            buttons: [
              {
                text: 'OK',
                role: 'confirm',
                handler: () => {
                  location.reload();
                },
              },
            ],
          });
          await alert.present();
        },
      });
  }
}
