import {
  HttpClient,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AuthenticationService} from './authentification.service';
import {
  BehaviorSubject,
  lastValueFrom,
  NEVER,
  Observable, tap,
  throwError,
  timer,
} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements HttpInterceptor {
  url = environment.api_url;

  httpOptions: any = {
    headers: new HttpHeaders(),
    withCredentials: true,
  };

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private platform: Platform
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Log request
    if (this.authService.getUser()?.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getUser().token}`,
        },
      });
    }

    // If platform is mobile add api_key
    if (this.platform.is('mobile') || this.platform.is('android')) {
      request = request.clone({
        setHeaders: {
          x: environment.api_key,
        },
      });
    }

    return next.handle(request).pipe(
      tap({}),
      catchError(error => {
        if (error.status === 401) {
          const refresh = request.method.toLowerCase() !== 'post';
          this.authService.refreshLogin(refresh).then();

          throw new Error('Unauthorized');
        }

        return throwError(error);
      })
    );
  }

  async check(): Promise<BehaviorSubject<boolean>> {
    try {
      await lastValueFrom(this.post('check'));

      return new BehaviorSubject(true);
    } catch (error) {
      return new BehaviorSubject(false);
    }
  }

  shouldRetry(error: HttpErrorResponse) {
    if (error.status >= 500) {
      return timer(1000);
    }

    throw error;
  }

  get(action: string, data?: any, headers?: any) {
    data = new HttpParams({fromObject: data});

    return this.http
      .get(this.url + action, {
        headers: this.httpOptions.headers,
        params: data,
        ...headers,
      })
      .pipe(
        retry({
          count: 2,
          delay: this.shouldRetry,
        }),
        catchError(this.handleError)
      );
  }

  prepareForm(data: any, form?: any, namespace?: any) {
    const formData = form || new FormData();
    let formKey;

    for (let property in data) {
      if (data.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }

        if (
          data[property] instanceof Object &&
          !(data[property] instanceof File)
        ) {
          this.prepareForm(data[property], formData, formKey);
        } else if (data[property] != null) {
          formData.append(formKey, data[property]);
        }
      }
    }

    return formData;
  }

  post(action: string, data?: any) {
    const formData = this.prepareForm(data);

    return this.http.post(this.url + action, formData, this.httpOptions).pipe(
      retry({
        count: 2,
        delay: this.shouldRetry,
      }),
      catchError(this.handleError)
    );
  }

  patch(action: string, data?: any) {
    data['_method'] = 'PATCH';
    return this.post(action, data);
  }

  delete(action: string) {
    return this.http.delete(this.url + action, this.httpOptions).pipe(
      retry({
        count: 2,
        delay: this.shouldRetry,
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: any): Observable<never> {
    if (error.status == 401) {
      return NEVER;
    }

    let errorMessage = error.error?.message;

    if (error.error?.errors) {
      Object.keys(error.error.errors).forEach((key) => {
        errorMessage += `${key}: ${error.error.errors[key]}\n`;
      });
    }

    return throwError(() => {
      if (errorMessage === undefined) return NEVER;

      return errorMessage.replace(/\n/g, '<br>');
    });
  }
}
