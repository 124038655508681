import {Injectable} from '@angular/core';
import {CanActivate, CanMatch} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'src/app/services/authentification.service';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanMatch, CanActivate {
  constructor(
    private authService: AuthenticationService,
    private nav: NavController
  ) {
  }

  canMatch(): Observable<boolean> | boolean {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          this.nav.navigateForward('/dash').then();
          return false;
        } else {
          return true;
        }
      })
    );
  }

  canActivate(): Observable<boolean> | boolean {
    return this.canMatch();
  }
}
