<ion-app>

  <ion-content [ngClass]="{ 'ion-hide': !connected }">
    <app-layout *ngIf="connected"></app-layout>
  </ion-content>

  <ion-content [ngClass]="{ 'ion-hide': connected }" class="ion-padding">
    <app-no-connection (refresh)="refresh()"></app-no-connection>
  </ion-content>
</ion-app>
