import { Injectable } from '@angular/core';
import { CanActivate, CanMatch } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanMatch, CanActivate {
  constructor(
    private authService: AuthenticationService,
    private nav: NavController,
  ) {}

  canMatch(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          const user = this.authService.getUser();

          let today = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
          );

          // Check if user is subscribed
          if (
            !user.user.subscribe ||
            new Date(user.user?.subscribe?.date_end).getTime() < today.getTime()
          ) {
            // Check if user is in trial
            if (
              !user.user.trial_ends_at ||
              new Date(user.user.trial_ends_at).getTime() < today.getTime()
            ) {
              this.nav.navigateRoot('/checkout').then();
            }
          }

          return true;
        } else {
          this.nav.navigateForward('/auth').then();
          return false;
        }
      }),
    );
  }

  canActivate(): Observable<boolean> {
    return this.canMatch();
  }
}
