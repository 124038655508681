import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LayoutComponent } from './layout/layout.component';
import { ReportComponent } from './report/report.component';
import { ReportFormComponent } from './report/report-form/report-form.component';
import { ChatComponent } from './report/chat/chat.component';
import { AppRoutingModule } from '../app-routing.module';
import { GdprComponent } from './gdpr/gdpr.component';

@NgModule({
  declarations: [
    LayoutComponent,
    ReportComponent,
    ReportFormComponent,
    ChatComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    GdprComponent,
  ],
  exports: [LayoutComponent],
})
export class SharedModule {}
