import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  cookies: string = '';

  constructor(private api: ApiService, private modalCtrl: ModalController) {}

  ngOnInit() {
    this.api.get('settings/cookies').subscribe((data: any) => {
      this.cookies = data.config;
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
