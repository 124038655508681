<form
  (ngSubmit)="authService.login(form.value)"
  [formGroup]="form"
  [ngClass]="login ? 'login' : ''"
>
  <ion-item lines="full">
    <ion-label>Email</ion-label>
    <ion-input
      (change)="autoComplete($event, 'email')"
      autocomplete="email"
      formControlName="email"
      required="true"
      type="email"
    ></ion-input>
  </ion-item>

  <ion-item lines="full">
    <ion-label>Parolă</ion-label>
    <ion-input
      (change)="autoComplete($event, 'password')"
      autocomplete="password"
      formControlName="password"
      required="true"
      type="password"
    ></ion-input>
  </ion-item>

  <ion-button
    [disabled]="form.invalid || form.pristine"
    expand="full"
    shape="round"
    type="submit"
  >
    <ion-icon name="log-in" slot="end"></ion-icon>
    {{ clearCache ? "Reautentificare" : "Intră în cont" }}
  </ion-button>

  <!-- clearCache -->
  <ion-label
    (click)="logout()"
    *ngIf="clearCache"
    class="ion-text-center pointer"
  >
    <ion-text color="danger">
      <h2 style="margin-top: 10px">Deconectare</h2>
    </ion-text>
  </ion-label>
</form>
