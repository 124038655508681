import { Injectable } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  editGdpr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private modalCtrl: ModalController,
    private platform: Platform,
    private nav: NavController
  ) {}

  async error(message: string) {
    const error = await this.alertController.create({
      header: 'Eroare',
      message: message,
      buttons: ['OK'],
    });

    await error.present();
  }

  upper(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject!.id ? itemObject.id : index;
  }

  keyboard() {
    return;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  async toast(
    message: string,
    position: 'top' | 'middle' | 'bottom',
    duration: number | null = 3000
  ) {
    let data: any = {
      message: message,
      position: position,
    };

    if (duration !== null) {
      data = {
        duration: duration,
        ...data,
      };
    }

    const toast = await this.toastController.create(data);

    await toast.present();
  }

  makeArray(elements: number) {
    return [...Array(elements).keys()];
  }

  redirect(location: string, config = {}) {
    this.nav.navigateForward([location], config).then();
  }

  secondsToMinutes(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  async openModal(component: any) {
    const modal = await this.modalCtrl.create({
      component: component,
    });

    modal.present().then();
  }

  stripHtml(html: string) {
    // Remove all html tags
    return html.replace(/<[^>]*>/g, ' ').replace(/&#\d+;/g, '');
  }

  // Timeout function
  timeout(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Compare two arrays
  compareArrays(array1: any[], array2: any[]) {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  }
}
