import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginComponent } from 'src/app/pages/dash/auth/login/login.component';
import { AuthenticationService } from 'src/app/services/authentification.service';
import { BaseService } from 'src/app/services/base.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  appPages: any = [
    {
      title: 'Activitate',
      url: '/dash',
      icon: 'home',
    },
    {
      title: 'Examen',
      url: '/exam',
      icon: 'reader',
    },
    {
      title: 'Profil',
      url: '/profile',
      icon: 'person',
    },
    {
      title: 'Performanță',
      url: '/performance',
      icon: 'bar-chart',
    },
    {
      title: 'Forum',
      url: '/forum',
      icon: 'chatbubbles',
    },
    // {
    //   title: 'Audio',
    //   url: '/audio',
    //   icon: 'headset',
    // },
  ];

  avatarDoctor: string = 'assets/icons/doctor.png';

  logged: boolean = false;
  user: any;
  homepage: boolean = false;
  greeting: string;
  isSubscribed: boolean = false;
  days: number = 0;
  daysMessage: null | string = null;
  hideMenu: boolean = false;

  constructor(
    public authService: AuthenticationService,
    public base: BaseService,
    private modalCtrl: ModalController,
    private router: Router
  ) {
    this.init();
  }

  init() {
    this.authService.userData.subscribe((user) => {
      if (user) {
        // Reset days message
        this.daysMessage = null;

        this.user = user.user;
        this.logged = true;

        // Days left, if never subscribed, dateEnd will be null for checking if is last day
        const dateEnd = this.user?.subscribe?.date_end
          ? new Date(this.user?.subscribe?.date_end)
          : null;
        if (dateEnd) {
          // Get current date
          const dateNow = new Date();
          // Get days left
          this.days =
            (1 +
              (dateEnd.getTime() - dateNow.getTime()) /
                (1000 * 60 * 60 * 24)) >>
            0;

          // Check if is last day
          if (this.days === 0) {
            this.daysMessage = 'Abonamentul expiră astăzi';
          } else if (this.days < 0) {
            this.daysMessage = 'Abonamentul a expirat';
          }
        }
      } else {
        this.logged = false;
        this.user = null;
        this.days = 0;
      }

      // Check if 3 days left
      if (this.days <= 3 && this.days > 0) {
        this.daysMessage =
          this.days === 1
            ? 'Abonamentul expiră mâine'
            : `Abonamentul expiră în ${this.days} zile`;
      }
    });

    // Subscribe and get value when page changes
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        // Check if is homepage or redirected to homepage
        this.hideMenu =
          // homepage
          event.url === '/' ||
          event.urlAfterRedirects === '/' ||
          // demo page
          event.url === 'demo' ||
          event.urlAfterRedirects === 'demo' ||
          // play page
          event.url.includes('play') ||
          event.urlAfterRedirects.includes('play');

        if (!this.hideMenu && !this.isSubscribed) {
          this.isSubscribed = true;

          // Check refresh login
          this.authService.userData
            .pipe(
              filter((user) => user?.refreshLogin === true),
              take(1)
            )
            .subscribe({
              next: () => {
                this.openModal();
              },
              complete: () => {
                this.isSubscribed = false;
              },
            });
        }
      });

    // Greeting Message
    const currentHour = new Date().getHours();
    this.greeting =
      (currentHour >= 0 && currentHour <= 4) ||
      (currentHour >= 17 && currentHour <= 23)
        ? 'O seară plăcută,'
        : 'O zi frumoasă,';
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent,
      backdropDismiss: false,
      cssClass: 'authModal',
    });
    await modal.present();

    // Close modal after login
    this.authService.userData
      .pipe(filter((user) => user?.refreshLogin === false || user === null))
      .subscribe({
        next: () => {
          modal.dismiss();
        },
        complete: () => {
          this.authService.userData.unsubscribe();
        },
      });
  }

  logout() {
    this.authService.logout();
  }
}
