import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const window: {
  [key: string]: any;
};

@Injectable({
  providedIn: 'root',
})
export class FbPixelService {
  pixelId: string = environment.fb_pixel_id;
  isInitialized: boolean = false;

  constructor() {}

  initPixel() {
    // Check if pixel is already initialized
    if (this.pixelId && !this.isInitialized) {
      const script = document.createElement('script');
      script.innerHTML = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${this.pixelId}');
  fbq('track', 'PageView');
`;
      document.head.appendChild(script);

      this.fbq('track', 'PageView');

      this.isInitialized = true;
    }
  }

  fbq(...args: any[]): void {
    if (this.isInitialized && window['fbq']) {
      window['fbq'].apply(this, args);
    }
  }

  trackCustomEvent(eventName: string, eventData: any): void {
    this.fbq('trackCustom', eventName, eventData);
  }

  trackEvent(eventName: string, eventData: any): void {
    this.fbq('track', eventName, eventData);
  }
}
