<ion-grid *ngIf="!gdpr.signed || base.editGdpr.getValue()" [ngClass]="{edit: base.editGdpr.getValue()}"
          class="ion-padding">
  <ion-row>
    <ion-col>
      <ion-text>
        <h3>Cookie</h3>

        <p>
          Utilizam cookie-uri pentru a va oferi o experienta optima. Puteți
          configura cookie-urile manual sau sa le acceptați. Navigând in
          continuare sunteți de acord cu
          <ion-text (click)="openPolicy()" class="pointer" color="primary"
          >Politica de utilizare a cookie-urilor
          </ion-text
          >
        </p>
      </ion-text>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="base.editGdpr.getValue()" class="form">
    <ion-col>
      <ion-list>
        <ion-item lines="full">
          <ion-label>
            Esențiale
            <br/>
            <ion-text class="ion-text-wrap">
              <small>
                Aceste cookie-uri sunt necesare pentru afișarea secțiunilor
                vizuale, retinerea preferințelor si a coșului de cumpărături.
              </small>
            </ion-text>
          </ion-label>
          <ion-toggle checked="true" disabled="true" slot="end"></ion-toggle>
        </ion-item>

        <ion-item lines="full">
          <ion-label>
            Analiza
            <br/>
            <ion-text class="ion-text-wrap">
              <small>
                Analiza cookie-urilor este necesara pentru a va oferi o
                experienta optima.
              </small>
            </ion-text>
          </ion-label>
          <ion-toggle [(ngModel)]="gdpr.analytics" slot="end"></ion-toggle>
        </ion-item>

        <ion-item lines="full">
          <ion-label
          >Social
            <br/>
            <ion-text class="ion-text-wrap">
              <small>
                Aceste cookie-uri va permit conectarea la rețele sociale.
              </small>
            </ion-text>
          </ion-label>
          <ion-toggle [(ngModel)]="gdpr.social" slot="end"></ion-toggle>
        </ion-item>

        <ion-item lines="full">
          <ion-label
          >Publicitate
            <br/>
            <ion-text class="ion-text-wrap">
              <small>
                Aceste cookie-uri ne ajuta sa va oferim o reclama relevanta.
              </small>
            </ion-text>
          </ion-label>
          <ion-toggle [(ngModel)]="gdpr.ads" slot="end"></ion-toggle>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-buttons>
        <ion-button
          (click)="showEdit()"
          *ngIf="!base.editGdpr.getValue()"
          color="primary"
          expand="block"
          fill="outline"
        >
          Editează
        </ion-button>

        <ion-button
          (click)="save()"
          color="primary"
          expand="block"
          fill="outline"
        >
          {{ base.editGdpr.getValue() ? 'Salvează' : 'Accept'}}
        </ion-button>
      </ion-buttons>
    </ion-col>
  </ion-row>
</ion-grid>
