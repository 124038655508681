<!-- No connection message -->
<ion-content class="ion-padding">
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        <div class="ion-text-center">
          <ion-icon name="cloud-offline-outline"></ion-icon>
        </div>

        <ion-text>
          <h1>Conexiune întreruptă</h1>
        </ion-text>
      </ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ion-text>
        <p>
          Pentru a putea folosi aplicația, trebuie sa fiți conectat la internet.
        </p>
      </ion-text>

      <div class="ion-text-end ion-padding-top">
        <ion-button (click)="refresh.next(true)" shape="round">Reîncearcă</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
