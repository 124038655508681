import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { CookiesComponent } from '../../pages/home/footer/cookies/cookies.component';
import { BaseService } from '../../services/base.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { FbPixelService } from '../../services/fb-pixel.service';
import { HotjarService } from '../../services/hotjar.service';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule],
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss'],
})
export class GdprComponent implements OnInit {
  gdpr: {
    signed: boolean;
    analytics: boolean;
    social: boolean;
    ads: boolean;
  } = {
    signed: false,
    analytics: false,
    social: false,
    ads: false,
  };

  constructor(
    private storage: Storage,
    public base: BaseService,
    private fbPixel: FbPixelService,
    private hotjar: HotjarService,
  ) {
    this.storage.get('gdpr').then((gdpr) => {
      if (gdpr) {
        this.gdpr = gdpr;

        this.startTracking();
      }
    });
  }

  ngOnInit(): void {}

  openPolicy() {
    this.base.openModal(CookiesComponent).then();
  }

  showEdit() {
    this.base.editGdpr.next(true);
  }

  save() {
    this.gdpr.signed = true;

    if (!this.base.editGdpr.getValue()) {
      this.gdpr.analytics = true;
      this.gdpr.social = true;
      this.gdpr.ads = true;
    }

    this.startTracking();

    this.storage.set('gdpr', this.gdpr).then();

    this.base.editGdpr.next(false);
  }

  startTracking() {
    if (this.gdpr.signed) {
      if (this.gdpr.analytics || this.gdpr.social || this.gdpr.ads) {
        this.fbPixel.initPixel();
      }

      if (this.gdpr.analytics) {
        this.hotjar.initHotjar();
      }
    }
  }
}
