<ion-split-pane contentId="main-content" when="xl">
  <ion-menu
    [disabled]="!logged || hideMenu"
    class="menu"
    contentId="main-content"
    type="push"
  >
    <ng-container *ngIf="logged">
      <ion-header collapse="fade">
        <ion-toolbar class="ion-no-padding">
          <ion-item lines="full">
            <ion-avatar class="pointer" slot="start">
              <ion-menu-toggle auto-hide="false" routerLink="/profile">
                <img [src]="avatarDoctor" alt="Doctor"/>
              </ion-menu-toggle>
            </ion-avatar>

            <ion-label>
              <p>{{ greeting }}</p>

              <ion-menu-toggle auto-hide="false" class="pointer" routerLink="/profile">
                {{ user?.firstname }}
                {{ user?.lastname }}
              </ion-menu-toggle>

              <ion-menu-toggle *ngIf="daysMessage" auto-hide="false" class="pointer" routerLink="/checkout">
                <ion-text class="ion-text-wrap">
                  <p>{{ daysMessage }}</p>
                </ion-text>
              </ion-menu-toggle>
            </ion-label>
          </ion-item>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list id="menu-list">
          <ion-menu-toggle
            *ngFor="
              let page of appPages;
              let i = index;
              trackBy: base.trackItems
            "
            auto-hide="false"
          >
            <ion-item
              [routerLink]="[page.url]"
              class="menu-item"
              detail="false"
              lines="none"
              routerDirection="root"
              routerLinkActive="selected"
            >
              <ion-icon
                [ios]="page.icon + '-outline'"
                [md]="page.icon + '-sharp'"
                slot="start"
              ></ion-icon>
              <ion-label>{{ page.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-menu-toggle (click)="logout()" auto-hide="false" class="logout">
            <ion-item class="menu-item" detail="false" lines="none">
              <ion-icon
                ios="log-out-outline"
                md="log-out-sharp"
                slot="start"
              ></ion-icon>
              <ion-label>Deconectare</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </ion-menu>

  <ion-router-outlet id="main-content">
    <app-gdpr></app-gdpr>
  </ion-router-outlet>

</ion-split-pane>
